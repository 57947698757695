import React, { Component, createContext } from 'react';
import EventAggregator from 'components/helpers/event-aggregator';
import SportEventService from 'services/sport-event-service'
import { toUTCString, UTCToLocalDate, formatTimestampAsZulu } from 'components/helpers/date-helper';
import MatchHelper from 'components/helpers/match-helper';
import DateHelper from 'components/helpers/date-helper';
import MatchStore from 'components/stores/match-store'
import SessionStorageHelper from 'components/helpers/session-storage-helper'


const LiveMatchesContext = createContext({
  upcomingMatchesToday: [],
  liveMatches: [],
  recentlyClosedMatches: [],
  closedMatches: [],
  allMatchesToday: [],
  liveAndRecentlyClosedMatches : [],
  fetchStatus: undefined,
  isLoading : false,
  sortingTournamentMatches: []
});
const LiveMatchesConsumer = LiveMatchesContext.Consumer;
const liveUpdateInterval = 30 * 100;
const defaultState = {
  upcomingMatchesToday: [],
  liveMatches: [],
  recentlyClosedMatches: [],
  closedMatches: [],
  allMatchesToday: [],
  liveAndRecentlyClosedMatches : [],
  fetchStatus: undefined,
  sr_matches : null,
  isLoading : false,
};
let failureRefreshLimit = 10;

class LiveMatchesProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...defaultState,
      fetchMatches : this.fetchMatches.bind(this),
      setChosenTab : this.setChosenTab.bind(this),
      getChosenTab : this.getChosenTab.bind(this),
      getDateTabIndex : this.getDateTabIndex.bind(this),
      fromDate : this.getFromDate(),
      toDate : this.getToDate(),
      visitedMatches: [],
      sortingTournamentMatches: []
    }

    this.isRefetchOfStateNeeded = false;
  }

  componentDidMount() {
    
    EventAggregator.subscribe('onTouchStart',() => this.forceStateNeed());
    EventAggregator.subscribe('onPointerDown',() => this.forceStateNeed());

    this.fetchMatches(this.state.fromDate,this.state.toDate,false)
  }

  getCacheExpirationTimeStamp(){
    let now = new Date();
    return now.setMinutes(now.getMinutes() + 120)
  }

  getFromDate(){
    if(typeof window === 'undefined'){
      return null;
    }
    let localFromDate = DateHelper.moment().startOf('day').format();
    return SessionStorageHelper.getItem("live_match_provider_from_date") ? SessionStorageHelper.getItem("live_match_provider_from_date") : DateHelper.moment.parseZone(localFromDate).utc().format();
  }

  getToDate(){
    if(typeof window === 'undefined'){
      return null;
    }
    let localToDate = DateHelper.moment().endOf('day').format();
    return SessionStorageHelper.getItem("live_match_provider_to_date") ? SessionStorageHelper.getItem("live_match_provider_to_date") : DateHelper.moment.parseZone(localToDate).utc().format();
  }

  forceStateNeed(){
    if(this.isRefetchOfStateNeeded){
      this.isRefetchOfStateNeeded = false;
      this.fetchMatches(this.state.fromDate,this.state.toDate,false);
    }
  } 

  fetchMatches(from,to,isUserInitiated=false){
    clearTimeout(this.timeOutId);

    
    this.setState({
      fromDate : from, 
      toDate : to,
      isLoading : isUserInitiated
    }, () => {
      SportEventService.sportEventCalendarGroupingTournament(`?fromDate=${toUTCString(this.state.fromDate)}&toDate=${toUTCString(this.state.toDate)}`)
        .then(res => {
          this.setState({ sortingTournamentMatches: res,  isLoading : false });
        })
        .catch(err => {

        });
      SportEventService.sportEventCalendar(`?fromDate=${toUTCString(this.state.fromDate)}&toDate=${toUTCString(this.state.toDate)}`)
      .then(response => { 
        response = response instanceof Array ? response.filter(item => item.status !== "postponed") : response;

        this.setState({ 
          sr_matches : MatchHelper.sortMatchListOnDateAndTournamentAndId(response), }, () => this.updateSession())

        // return MatchStore.getMatches()
        // .then(matches => {
        //   if(matches instanceof Array && response instanceof Array){
        //     response = response.filter(item => !matches.find(match => match.matchId === item.id && match.isHidden === true));
        //   }
        //   })
      })
      .then(() => this.timeOutId = setTimeout(() => this.fetchMatches(this.state.fromDate,this.state.toDate,false), liveUpdateInterval))
      .catch(e => {
        clearTimeout(this.timeOutId);
        this.setState(defaultState);
        this.isRefetchOfStateNeeded = true;

        failureRefreshLimit--;
        if (failureRefreshLimit > 0) {
          setTimeout(() => this.fetchMatches(this.state.fromDate,this.state.toDate,false), 3000);
        } else {
          this.setState({ fetchStatus: 'error', isLoading : false });
        }
      })
    })
    
  }

  updateSession(){
    SessionStorageHelper.setItem("live_match_provider_from_date",this.state.fromDate, this.getCacheExpirationTimeStamp())
    SessionStorageHelper.setItem("live_match_provider_to_date",this.state.toDate,this.getCacheExpirationTimeStamp())
  }

  getMatchEndTime(match) {
    if (match.status === 'closed' && match.latestEventTime) {
      return formatTimestampAsZulu(match.latestEventTime);
    }
    const matchDate = UTCToLocalDate(match.scheduled);
    const matchEndDate = new Date(matchDate);
    return matchEndDate.setHours(matchDate.getHours() + 2);
  }

  getChosenTab(matchId) {
    const visitedMatch = this.state.visitedMatches.find(visitedMatch => visitedMatch.matchId === matchId)
    return visitedMatch ? visitedMatch.chosenTab : '';
  }

  setChosenTab(chosenMatchId, chosenTabAlias) {
    const recentlyVisitedMatch = {matchId:chosenMatchId, chosenTab:chosenTabAlias}
    let previouslyVisitedMatches = [...this.state.visitedMatches];
    const recentlyVisitedMatchIndex = previouslyVisitedMatches.findIndex(visitedMatch => visitedMatch.matchId === recentlyVisitedMatch.matchId);
    if(recentlyVisitedMatchIndex!==-1) {
      previouslyVisitedMatches[recentlyVisitedMatchIndex] = recentlyVisitedMatch; 
      this.setState({ visitedMatches:previouslyVisitedMatches });
    }
    else {
      this.setState(prevState => ({ visitedMatches: [...prevState.visitedMatches, recentlyVisitedMatch]}));
    }
  }

  getDateTabIndex() {
    let fromDate = new Date(this.state.fromDate);
    let todaysDate = new Date(DateHelper.moment().startOf('day').format());
    let diffInMilliseconds = fromDate.getTime() - todaysDate.getTime();
    let diffInDays = diffInMilliseconds / (1000 * 3600 * 24);
    return diffInDays;
  }

  render() {
    return <LiveMatchesContext.Provider value={this.state}>{this.props.children}</LiveMatchesContext.Provider>;
  }
}

export default LiveMatchesContext;
export { LiveMatchesConsumer, LiveMatchesProvider, liveUpdateInterval };