import axios from "axios";
import Cookies from 'universal-cookie';

const jwtKey = "auth._token.local";
const cookies = new Cookies();
const accessToken = cookies.get(jwtKey);
const client = axios.create();


client.defaults.headers.Authorization = `Bearer ${accessToken}`;
client.defaults.baseURL = `${process.env.API_URL}`;

client.interceptors.request.use(
    (config) => {
    if(accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
    }
    config.withCredentials = true;
    return config;
    },
    (error) => {
    return Promise.reject(error);
});


client.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        const code = parseInt(error.response && error.response.status);
        if (code === 401) {
        } else if (code === 403) {
            
        }
        return Promise.reject(error);
    }
);

export default client;