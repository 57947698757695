const moment = require('moment');
const TranslationStore = require('../stores/translation-store');
const UrlHelper = require('./url-helper');
const tournamentHelper = require('./tournament-helper');

class MatchHelper {
  getSlugFromMatch(match) {
    if (!match) {
      return null;
    }

    // if(match.tournamentName === 'NHL')
    //   debugger

    const matchId = this.getCleanSportRadarId(match.matchId ? match.matchId : match.id);
    let scheduled = '';
    // debugger
    if (match.scheduled && (match.scheduled.indexOf('-') > -1 || match.scheduled.indexOf('/') > -1)) {
      const arr = match.scheduled.split(/[-/+ :T]/);
      let dateWithOffset = new Date(match.scheduled);
      let date = new Date(dateWithOffset.getTime() + dateWithOffset.getTimezoneOffset() * 600000);
      // debugger
      // const date = match.scheduled.match(/(AM|PM)/) ?
      //   new Date(Date.UTC(arr[2], arr[0] - 1, arr[1], match.scheduled.indexOf('PM') > -1 ? parseInt(arr[3]) + 12 : arr[3], arr[4], 0)) :
      //   new Date(Date.UTC(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], 0));
      let year = match.scheduled.substring(0, 4);
      let month = match.scheduled.substring(5, 7);
      month = month.startsWith('0') ? month.substring(1, 2) : month;
      let day = match.scheduled.substring(8, 10);
      // scheduled = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
      scheduled = year + '-' + month + '-' + day;
    }

    const slugParts = [
      matchId,
      scheduled,
      match.competitorNameHome ? match.competitorNameHome : match.homeTeamName,
      match.competitorNameAway ? match.competitorNameAway : match.awayTeamName,
    ];

    return UrlHelper.convertStringToSlug(slugParts.join('-'), '-');
  }

  getCleanSportRadarId(matchId) {
    return matchId && matchId.lastIndexOf(':') > -1
      ? matchId.substring(matchId.lastIndexOf(':') + 1, matchId.length)
      : matchId;
  }

  getMatchStatusText(status, useShort) {
    if (this.isMatchLive(status)){
      return TranslationStore.getTranslation("live_match_status_live");
    } 
    else if (this.isMatchClosed(status)){
      return useShort ?
      TranslationStore.getTranslation("live_match_status_short_closed") :
      TranslationStore.getTranslation("live_match_status_closed")
    } 
    return ''
  }

  filterListOnSports(list, sports) {
    return list instanceof Array && list.filter(match => {

      if(!(match && match.sportId)){
        return false;
      }
      return sports instanceof Array ? sports.find(sport => sport.sportId === match.sportId) : null;
    });
  }

  sortMatchListOnDateAndTournamentAndId(list) {
    return list.sort((m1, m2) => {
      const match1Date = this.UTCToLocalDate(m1.scheduled);
      const match2Date = this.UTCToLocalDate(m2.scheduled);
      if (match1Date - match2Date !== 0) {
        return match1Date - match2Date;
      }
      const match1Tournament = m1.tournamentId ? parseInt(m1.tournamentId.match(/\d+/)[0]) : 0;
      const match2Tournament = m2.tournamentId ? parseInt(m2.tournamentId.match(/\d+/)[0]) : 0;
      if (match1Tournament - match2Tournament !== 0) {
        return match1Tournament - match2Tournament;
      }
      const match1Id = m1.id ? parseInt(m1.id.match(/\d+/)[0]) : 0;
      const match2Id = m2.id ? parseInt(m2.id.match(/\d+/)[0]) : 0;
      return match1Id - match2Id;
    });
  }

  removeGamesWithoutEvents(list) {
    const result = list.filter(item => {
      if (item.node) {
        return !tournamentHelper.tournamentsWithoutEvents.includes(item.node.tournamentId);
      }
      return !tournamentHelper.tournamentsWithoutEvents.includes(item.tournamentId);
    });
    return result;
  }

  UTCToLocalDate(date) {
    const format = date.indexOf('AM') > -1 || date.indexOf('PM') ? '' : 'YYYY-MM-DD[T]HH:mm:ss';
    return moment.utc(date, format).toDate();
  }

  isMatchUpcoming(status) {
    return status && status === 'not_started';
  }

  isMatchLive(status) {
    return status && status === 'live';
  }

  isMatchClosed(status) {
    return status && (status === 'closed' || status === 'ended');
  }

  isMatchPaused(status) {
    return status && ((status === 'halftime') || (status === 'pause')  || (status === 'break_start'));
  }

  isMatchClosedAfterPenalties(status) {
    return status && (status === 'ap');
  }

  isMatchClosedAfterExtraTime(status) {
    return status && (status === 'aet');
  }

  isMatchFootball(sportId) {
    return sportId && (sportId === 'e:sport:1');
  }

  isMatchHockey(sportId) {
    return sportId && (sportId === 'e:sport:5');
  }

  isMatchHandball(sportId) {
    return sportId && (sportId === 'e:sport:20');
  }

  getMatchTimeSoccer(currentMinut,props) {
    if(props.elapsed && props.elapsed > 0) {
      if(props.injuryElapsed && props.injuryElapsed > 0) {
        return `${props.elapsed} + ${props.injuryElapsed}`
      } else {
        return props.elapsed;
      }
    }
    let latestEventStoppageTime = props.latestEventStoppageTime !== null && props.latestEventStoppageTime ? parseInt(props.latestEventStoppageTime) : 0;
    let latestEventPeriod = props.latestEventPeriod ? props.latestEventPeriod : null;
    let latestEventPeriodType = props.latestEventPeriodType ? props.latestEventPeriodType : null;


    if (latestEventPeriodType === 'penalties') {
      return TranslationStore.getTranslation("live_match_status_penalties");
    } else if (latestEventPeriod === 4 && latestEventPeriodType === 'Overtime') {
      return currentMinut <= 120 && !latestEventStoppageTime ? currentMinut : `120+${(currentMinut + latestEventStoppageTime) % 120}`
    } else if (latestEventPeriod === 3 && latestEventPeriodType === 'Overtime') {
      return currentMinut <= 105 && !latestEventStoppageTime ? currentMinut : `105+${(currentMinut + latestEventStoppageTime) % 105}`;
    } else if (latestEventPeriod === 2 && latestEventPeriodType === 'regular_period') {
      return currentMinut <= 90 && !latestEventStoppageTime ? currentMinut : `90+${(currentMinut + latestEventStoppageTime) % 90}`;
    } else if (latestEventPeriod === 1 && latestEventPeriodType === 'regular_period') {
      return currentMinut <= 45 && !latestEventStoppageTime ? currentMinut : `45+${(currentMinut + latestEventStoppageTime) % 45}`;
    } else {
      return '';
    }
  }

  getHockeyLiveMatchStatus(matchStatus) {
    if (matchStatus) {
      if (matchStatus === 'penalties') {
        return TranslationStore.getTranslation("live_match_status_penalties");
      } else if (matchStatus === 'overtime') {
        return TranslationStore.getTranslation("live_match_status_overtime");
      } else if (matchStatus === '3rd_period') {
        return TranslationStore.getTranslation("live_match_status_3rd_period");
      } else if (matchStatus === '2nd_period') {
        return TranslationStore.getTranslation("live_match_status_2nd_period");
      } else if (matchStatus === '1st_period') {
        return TranslationStore.getTranslation("live_match_status_1st_period");
      }
    }
    return '';
  }
}

const helper = new MatchHelper();
module.exports = helper;
