import TeamHelper from 'components/helpers/team-helper';
import axios from 'components/helpers/axios';

const url = 'api/sportevent';

class SportEventService {

  getSportEvent(sportEventId) {
    return axios.get(`${url}/${sportEventId}`);
  }

  sportEvent(sportEventId) {
    return axios.get(`${url}/event?sportEventId=${sportEventId}`);
  }

  async sportEventReferat(sportEventId) {
    return await axios.get(`${url}/referat?sportEventId=${sportEventId}`);
  }

  sportEventCalendar(filter = '') {
    return axios.get(`${url}/calendar-new${filter}`).then((response) =>
      TeamHelper.updateSportEventsWithAttributesFromTeamStore(response.data)
    );
  }

  sportEventCalendarGroupingTournament(filter = '') {
    return axios.get(`${url}/calendar-tournament-new${filter}`).then((response) =>
      TeamHelper.updateSportEventsWithAttributesFromTeamStore(response.data)
    );
  }

  GetFutureSportEvents(model) {
    return axios.post(`${url}/getFutureSportEventsAsync`, model);
  }

  GetSportEventPeriodScore(sportEventId) {
    return axios.get(`${url}/period-score/${sportEventId}`);
  }

  AddOrChangeAdminSportEvent(model) {
    return axios.post(`${url}/addOrChangeAdminSportEvent`, model);
  }

  GetCustomAdminTexts(sportEventId, language = 1) {
    return axios.get(`${url}/getCustomAdminTexts?sportEventId=${sportEventId}&language=${language}`);
  }

  sportEventHockeyStats(sportEventId) {
    return axios.get(`${url}/hockeystats?sportEventId=${sportEventId}`);
  }

  sportEventSoccerStats(sportEventId) {
    return axios.get(`${url}/soccerstats?sportEventId=${sportEventId}`);
  }

  getSportEventTeams(sportEventId) {
    return axios.get(`${url}/teams/${sportEventId}`);
  }

  sportEventVenue(sportEventId) {
    return axios.get(`${url}/venue?sportEventId=${sportEventId}`);
  }

  getFormStatistic(sportEventId) {
    return axios.get(`${url}/${sportEventId}/formStatistic`);
  }

  getTimelines(sportEventId, sportId) {
    return axios.get(`${url}/timelines/${sportEventId}?sportId=${sportId}`);
  }

  getWinnerPredictNumber(sportEventId) {
    return axios.get(`${url}/getWinnerPredictNumber?sportEventId=${sportEventId}`)
  }

  saveWinnerPredictNumber(predict, sportEventId, extendedOptions){
    return axios.get(`${url}/saveWinnerPredictNumber?sportEventId=${sportEventId}&predict=${predict}`,extendedOptions)
  }

  sportEventTeams(sportEventId="sr:match:16607843",extendedOptions=null) {
    return axios.get(`${url}/missingplayers?sportEventId=${sportEventId}`,extendedOptions)
  }

  updateSquad(sportEventId = "sr:match:16607843", homeTeam = true, extendedOptions = null) {
    return axios.get(`${url}/updateSquad?sportEventId=${sportEventId}&homeTeam=${homeTeam}`,extendedOptions)
  }

  getMatchLineups(sportEventId) {
    return axios.get(`${url}/getMatchLineups?sportEventId=${sportEventId}`);
  }
  
}

const singleton = new SportEventService();
export default singleton;
