/**
 * Implement Gatsby's Browser APIs in this file.
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from 'react';
import 'whatwg-fetch';
import Polyfill from 'polyfill/polyfill.js';
import Waves from 'node-waves';
import InteractionHelper from 'components/helpers/interaction-helper.js';
import StoreFactory from 'components/block-elements/store-factory/store-factory';
import { GlobalContextProvider } from 'components/stores/global-context';
import { LiveMatchesProvider } from 'components/stores/live-matches-context';
import GlobalEventAggregatorHelper from 'components/helpers/global-event-aggregator-helper';

Polyfill.init();

export const onClientEntry = () => {
  if (typeof window !== 'undefined') {
    if (window?.location?.protocol !== 'https:') {
      //window.location.href = 'https:' + window.location.href.substring(window.location.protocol.length);
    }

    InteractionHelper.setDefaultState();
    Waves.init({ delay: 100 });
    GlobalEventAggregatorHelper.init();
  }
};

export const registerServiceWorker = () => {
  if (typeof window !== 'undefined' && navigator !== undefined && 'serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      navigator.serviceWorker
        .register('/sw.js', { scope: '/' })
        .then(registration => {
        })
        .catch(registrationError => {
        });
    });

    window.addEventListener('beforeinstallprompt', function(e) {
      e.preventDefault();
      //deferredPrompt = e;
    });
  }
};

export const shouldUpdateScroll = ({
  routerProps,
  getSavedScrollPosition
}) => {
  // const currentPosition = getSavedScrollPosition(location)
  // const queriedPosition = getSavedScrollPosition({ pathname: `/random` })

  window.scrollTo(...([0, 0]))

  return false
}

export const onRouteUpdate = () => {
  window.locations = window.locations || [document.referrer]
  window.locations.push(window.location.href)
  window.previousPath = window.locations[window.locations.length - 2]
}

export const wrapRootElement = ({ element }) => (<StoreFactory><GlobalContextProvider><LiveMatchesProvider>{element}</LiveMatchesProvider></GlobalContextProvider></StoreFactory>)

export const onPreRouteUpdate = ({ location, prevLocation }) => {
  if(process.env.NODE_ENV === 'production') {
      window.___loader.loadAppData().then(appData => {
          if(appData && appData.webpackCompilationHash !== window.___webpackCompilationHash) {
              window.___webpackCompilationHash = appData.webpackCompilationHash;
              window.location = location.pathname;
          }
      });
  }
}