class TranslationStore {

  constructor() {
      this.translations = []
  }

  populate(list){
      let translations = [];
      list.forEach(group => group.translations.forEach(translation => translations.push(translation)))
      this.translations = translations;
  }

  getTranslation(value){
      let foundTranslation = this.translations.find(translation => translation.value === value);
      return foundTranslation ? foundTranslation.label : "";
  }

}

const singleton = new TranslationStore();
module.exports = singleton;