const TeamStore = require('../stores/team-store')


class TeamHelper {

  updateSportEventsWithAttributesFromTeamStore(matches){
    if(matches instanceof Array && matches.length){
      matches.forEach(match => {
        let foundAwayTeam = TeamStore.getTeam(match.awayTeamId);
        let foundHomeTeam = TeamStore.getTeam(match.homeTeamId);

        if(foundAwayTeam){
          match.awayTeamAbb = foundAwayTeam.abbr ? foundAwayTeam.abbr : match.awayTeamAbb;
          match.awayTeamNameOverride = foundAwayTeam.name ? foundAwayTeam.name : match.awayTeamName;
        }

        if(foundHomeTeam){
          match.homeTeamAbb = foundHomeTeam.abbr ? foundHomeTeam.abbr : match.homeTeamAbb;
          match.homeTeamNameOverride = foundHomeTeam.name ? foundHomeTeam.name : match.homeTeamName;
        }
      })  
    }

    return matches;
  }

  updateMatchDataWithAttributesFromTeamStore(match){
    if(match.competitorIdAway && match.competitorIdHome){
      let foundAwayTeam = TeamStore.getTeam(match.competitorIdAway);
      let foundHomeTeam = TeamStore.getTeam(match.competitorIdHome);

      if(foundAwayTeam){
          match.awayTeamAbb = foundAwayTeam.abbr ? foundAwayTeam.abbr : match.awayTeamAbb;
          match.awayTeamName = foundAwayTeam.name ? foundAwayTeam.name : match.awayTeamName;
      }

      if(foundHomeTeam){
          match.homeTeamAbb = foundHomeTeam.abbr ? foundHomeTeam.abbr : match.homeTeamAbb;
          match.homeTeamName = foundHomeTeam.name ? foundHomeTeam.name : match.homeTeamName;
      }
    }
    return match; 
  }

  updateTournamentRankingPlayerWithAttributesFromTeamStore(rankings){
    if(rankings instanceof Array){
      rankings.forEach(ranking => {
        let foundRanking = TeamStore.getTeam(ranking.competitorId);
        if(foundRanking){
          ranking.competitorName = foundRanking.name ? foundRanking.name : ranking.competitorName;
        }
      })
    }
    return rankings instanceof Array ? rankings : [];
  }

  updateTournamentBookingPlayerWithAttributesFromTeamStore(bookings){
    if(bookings instanceof Array){
      bookings.forEach(booking => {
        let foundBooking = TeamStore.getTeam(booking.competitorId);
        if(foundBooking){
          booking.competitorName = foundBooking.name ? foundBooking.name : booking.competitorName;
          booking.competitorAbbreviation = foundBooking.abbr? foundBooking.abbr : booking.competitorAbbreviation;
        }
      })
    }
    return bookings instanceof Array ? bookings : [];
  }

  updateTournamentStandingGroupWithAttributesFromTeamStore(standings){
    if(standings instanceof Array){
      standings.forEach(standing => {
        if(standing.standingGroups instanceof Array){
          standing.standingGroups.forEach(group => {
            if(group.standingRows instanceof Array){
              group.standingRows.forEach(team => {
                let foundTeam = TeamStore.getTeam(team.teamId);
                if(foundTeam){
                  team.teamName = foundTeam.name ? foundTeam.name : team.teamName;
                }
              })
            }
          })
        }
      })
    }

    return standings;
  }

}

const helper = new TeamHelper();
export default helper;