const tournamentPriorityList = [
  {
    tournamentName:'World Cup',
    tournamentId:'e:tournament:76',
    sportId:'e:sport:1',
  },
  {
    tournamentName:'EM Qualification',
    tournamentId:'sr:tournament:27',
    sportId:'e:sport:1',
  },
  {
    tournamentName:'EM',
    tournamentId:'e:tournament:292',
    sportId:'e:sport:1',
  },
  {
    tournamentName:'UEFA Nations League A',
    tournamentId:'e:tournament:9806',
    sportId:'e:sport:1',
  },
  {
    tournamentName:'UEFA Nations League B',
    tournamentId:'e:tournament:9807',
    sportId:'e:sport:1',
  },
  {
    tournamentName:'UEFA Nations League C',
    tournamentId:'e:tournament:9808',
    sportId:'e:sport:1',
  },
  {
    tournamentName:'UEFA Nations League D',
    tournamentId:'e:tournament:9809',
    sportId:'e:sport:1',
  },
  {
    tournamentName:'World Cup',
    tournamentId:'sr:tournament:3',
    sportId:'e:sport:5',
  },
  {
    tournamentName:'OS',
    tournamentId:'sr:tournament:272',
    sportId:'e:sport:5',
  },
  {
    tournamentName:'Allsvenskan',
    tournamentId:'e:tournament:67',
    sportId:'e:sport:1',
  },
  {
    tournamentName:'Superettan',
    tournamentId:'sr:tournament:46',
    sportId:'e:sport:1',
  },
  {
    tournamentName:'Div 1 norra',
    tournamentId:'sr:tournament:67',
    sportId:'e:sport:1',
  },
  {
    tournamentName:'Div 1 södra',
    tournamentId:'sr:tournament:68',
    sportId:'e:sport:1',
  },
  {
    tournamentName:'Div 2, Östra Götaland',
    tournamentId:'sr:tournament:395',
    sportId:'e:sport:1',
  },
  {
    tournamentName:'Div 2, Norra Svealand',
    tournamentId:'sr:tournament:70',
    sportId:'e:sport:1',
  },
  {
    tournamentName:'Div 2, Norrland',
    tournamentId:'sr:tournament:71',
    sportId:'e:sport:1',
  },
  {
    tournamentName:'Div 2, Södra Götaland',
    tournamentId:'sr:tournament:72',
    sportId:'e:sport:1',
  },
  {
    tournamentName:'Div 2, Västra Götaland',
    tournamentId:'sr:tournament:73',
    sportId:'e:sport:1',
  },
  {
    tournamentName:'Div 2, Södra Svealand',
    tournamentId:'sr:tournament:74',
    sportId:'e:sport:1',
  },
  {
    tournamentName:'Div 2, Norra Götaland',
    tournamentId:'sr:tournament:868',
    sportId:'e:sport:1',
  },
  {
    tournamentName:'Svenska Cupen',
    tournamentId:'sr:tournament:80',
    sportId:'e:sport:1',
  },
  {
    tournamentName:'European Hockey Tour',
    tournamentId:'sr:tournament:467',
    sportId:'e:sport:5',
  },
  {
    tournamentName:'SHL inklusive slutspel',
    tournamentId:'e:tournament:21',
    sportId:'e:sport:5',
  },
  {
    tournamentName:'Hockeyallsvenskan inklusive kval',
    tournamentId:'e:tournament:22',
    sportId:'e:sport:5',
  },
  {
    tournamentName:'Hockeyettan',
    tournamentId:'e:tournament:9957',
    sportId:'e:sport:5',
  },
  {
    tournamentName:'Champions League',
    tournamentId:'e:tournament:42',
    sportId:'e:sport:1',
  },
  {
    tournamentName:'Premier League',
    tournamentId:'e:tournament:47',
    sportId:'e:sport:1',
  },
  {
    tournamentName:'Championship',
    tournamentId:'sr:tournament:18',
    sportId:'e:sport:1',
  },
  {
    tournamentName:'La Liga',
    tournamentId:'e:tournament:87',
    sportId:'e:sport:1',
  },
  {
    tournamentName:'Serie A',
    tournamentId:'e:tournament:55',
    sportId:'e:sport:1',
  },
  {
    tournamentName:'Bundesliga',
    tournamentId:'e:tournament:54',
    sportId:'e:sport:1',
  },
  {
    tournamentName:'Ligue 1',
    tournamentId:'e:tournament:53',
    sportId:'e:sport:1',
  },
  {
    tournamentName:'NHL',
    tournamentId:'e:tournament:24',
    sportId:'e:sport:5',
  },
  {
    tournamentName:'Vänskapslandskamper',
    tournamentId:'',
    sportId:'e:sport:1',
  },
  {
    tournamentName:'Europa League',
    tournamentId:'e:tournament:73',
    sportId:'e:sport:1',
  },
  {
    tournamentName:'FA-cupen',
    tournamentId:'e:tournament:132',
    sportId:'e:sport:1',
  },
  {
    tournamentName:'EFL Cup',
    tournamentId:'e:tournament:133',
    sportId:'e:sport:1',
  },
  {
    tournamentName:'OS',
    tournamentId:'',
    sportId:'e:sport:20',
  },
  {
    tournamentName:'VM',
    tournamentId:'sr:competition:77',
    sportId:'e:sport:20',
  },
  {
    tournamentName:'EM',
    tournamentId:'',
    sportId:'e:sport:20',
  },
  {
    tournamentName:'Handbollsligan',
    tournamentId:'sr:competition:161',
    sportId:'e:sport:20',
  },
  {
    tournamentName:'VM',
    tournamentId:'sr:tournament:403',
    sportId:'e:sport:25',
  },
  {
    tournamentName:'Elitserien',
    tournamentId:'sr:tournament:444',
    sportId:'e:sport:25',
  }
];

const tournamentsWithoutEvents = [
  {
    tournamentName:'Div 1 norra',
    tournamentId:'sr:tournament:67',
    sportId:'e:sport:1',
  },
  {
    tournamentName:'Div 1 södra',
    tournamentId:'sr:tournament:68',
    sportId:'e:sport:1',
  },
  {
    tournamentName:'Div 2, Östra Götaland',
    tournamentId:'sr:tournament:395',
    sportId:'e:sport:1',
  },
  {
    tournamentName:'Div 2, Norra Svealand',
    tournamentId:'sr:tournament:70',
    sportId:'e:sport:1',
  },
  {
    tournamentName:'Div 2, Norrland',
    tournamentId:'sr:tournament:71',
    sportId:'e:sport:1',
  },
  {
    tournamentName:'Div 2, Södra Götaland',
    tournamentId:'sr:tournament:72',
    sportId:'e:sport:1',
  },
  {
    tournamentName:'Div 2, Västra Götaland',
    tournamentId:'sr:tournament:73',
    sportId:'e:sport:1',
  },
  {
    tournamentName:'Div 2, Södra Svealand',
    tournamentId:'sr:tournament:74',
    sportId:'e:sport:1',
  },
  {
    tournamentName:'Div 2, Norra Götaland',
    tournamentId:'sr:tournament:868',
    sportId:'e:sport:1',
  }
];

module.exports = { tournamentPriorityList, tournamentsWithoutEvents };
