import React, { Component, createContext } from 'react';
import ThemeHelper from 'components/helpers/theme-helper.js';
const GlobalContext = createContext({
    isMainVisible : true,
    theme: 'mcm',
    setValue: function(){}
});
const GlobalContextConsumer = GlobalContext.Consumer;


class GlobalContextProvider extends Component {

    constructor(props){
        super(props)

        this.state = {
            theme : ThemeHelper.getTheme(),
            isMainVisible : true,
            setValue : this.setValue.bind(this)
        }
    }

    setValue(value){
        this.setState(value)
    }

    render() {
        return <GlobalContext.Provider value={this.state}>{this.props.children}</GlobalContext.Provider>;
    }
}

export default GlobalContext;
export {GlobalContextConsumer, GlobalContextProvider};
