import moment from 'moment';
import 'moment/locale/sv';

class DateHelper {
    /**
     * @param {Date} date
     * @returns {String} Date as string with format YYYY-MM-DD
     */

    constructor() {
        moment.locale("sv");
        this.moment = moment;
    }

    toStringYYYYMMDD(date) {
        if (!date) {
            return '';
        }
        const year = date.getFullYear();
        const monthInt = date.getMonth() + 1;
        let month = monthInt;
        if (monthInt < 10) {
            month = '0' + monthInt;
        }
        const dayInt = date.getDate();
        let day = dayInt;
        if (dayInt < 10) {
            day = '0' + dayInt;
        }

        return `${year}-${month}-${day}`;
    }

    addLeadingZero(num) {
        return num < 10 ? '0' + num : num;
    }

    toStringYYYYMMDDTime(date) {
        if (!date) {
            return '';
        }
        const year = date.getFullYear();
        const monthInt = date.getMonth() + 1;
        let month = monthInt;
        if (monthInt < 10) {
            month = '0' + monthInt;
        }
        const dayInt = date.getDate();
        let day = dayInt;
        if (dayInt < 10) {
            day = '0' + dayInt;
        }
        const hours = this.addLeadingZero(date.getHours());
        const minutes = this.addLeadingZero(date.getMinutes());

        return `${year}-${month}-${day}, ${hours}:${minutes}`;
    }

    formatTimestampAsZulu(time) {
        return time.charAt(time.length - 1) === "Z" ? moment(time).utc().format() : moment(time + "Z").utc().format();
    }

    /**
     *
     * @param {Date} date
     * @param {number} days
     * @returns {Date} Incremented date
     */
    addDays(date, days) {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }

    /**
     * @param {Date} date
     * @returns {String} Time from date as string formatted with HH:MM and localisation of browser settings. Ex: 14:35
     */

    toStringTimeHHMM(date) {
        // debugger
        if (!(date instanceof Date)) {
            return '';
        }

        return date.toLocaleTimeString('sv', { hour: '2-digit', minute: '2-digit', timeZone: 'Europe/Stockholm' });
    }

    /**
     *
     * @param {Date} date
     * @returns {string} Date formatted month date and weekday - for sv it is "tisdag 28 maj"
     */
    toStringMMDDWeekday(date) {
        if (!(date instanceof Date)) {
            return '';
        }

        return date.toLocaleDateString('sv', {
            month: 'long',
            day: 'numeric',
            weekday: 'long',
            timeZone: 'Europe/Stockholm',
        });
    }

    /**
     * @param {Date} date
     * @returns {String} Date as string with format {DD  Month} and localisation of locale settings
     */

    toStringDDMonth(date) {
        if (!(date instanceof Date)) {
            return '';
        }

        return date.toLocaleDateString('sv', { day: 'numeric', month: 'long', timeZone: 'Europe/Stockholm' });
    }

    /**
     * @param {Date} date
     * @returns {String} Date as string with format {DD  Month Year} and localisation of locale settings
     */

    toStringDDMonthYear(date) {
        if (!(date instanceof Date)) {
            return '';
        }

        return date.toLocaleDateString('sv', {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
            timeZone: 'Europe/Stockholm',
        });
    }

    toStringWeekday(date) {
        if (!(date instanceof Date)) {
            return '';
        }

        return date.toLocaleDateString('sv', { weekday: 'long', timeZone: 'Europe/Stockholm' });
    }

    toUTCString(date) {
        return moment.utc(date).format('YYYY-MM-DD[T]HH:mm:ss');
    }

    utcStringToUTCDate(utcString) {
        const arr = utcString.split(/[-/+ :T]/);
        return utcString.match(/(AM|PM)/) ?
            new Date(Date.UTC(arr[2], arr[0] - 1, arr[1], utcString.indexOf('PM') > -1 ? parseInt(arr[3]) + 12 : arr[3], arr[4], 0)) :
            new Date(Date.UTC(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], 0));
    }

    UTCToLocalDate(date) {
        
        const format = (date.indexOf('AM') > -1 || date.indexOf('PM')) ? '' : 'YYYY-MM-DD[T]HH:mm:ss';
        return moment(date, format).local().toDate();
    }

    toLocalDateTime(date) {
        const dateTimeUtc = moment.utc(date).toDate();
        return moment(dateTimeUtc).local().format();
    }

    formatDateAndTimeReadable(date) {
        return moment(date).format('DD MMM YYYY, HH:mm');
    }

    getTimeFromDateObject(date) {
        return moment(date).format("HH:mm");
    }

    /**
     * @param {Date} date
     * @param {number} offsetInMinutes offset in minutes, use as grace period
     * @returns {Boolean} returns true if date is passed, optional offset provided
     */

    isMatchStartTimePassed(date) {
        const currentDateTime = moment();
        return moment(date).isBefore(currentDateTime);
    }

    isDatePassed(date, offsetInMinutes = 0) {
        const dateWithOffset = new Date(date).setMinutes(date.getMinutes() + offsetInMinutes);
        return dateWithOffset < new Date();
    }

    isEqualYYYYMMDDD(date1, date2) {
        if (date1 instanceof Date && date2 instanceof Date) {
            return date1.toISOString().substring(0, 10) === date2.toISOString().substring(0, 10);
        }
        return false;
    }

    isEqualYYYYMM(date1, date2) {
        if (date1 instanceof Date && date2 instanceof Date) {
            return date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth();
        }
        return false;
    }

    isLessThanYYYYMM(date1, date2) {
        if (date1 instanceof Date && date2 instanceof Date) {
            return date1.getFullYear() <= date2.getFullYear() && date1.getMonth() < date2.getMonth();
        }
        return false;
    }

    isGreaterThanYYYYMM(date1, date2) {
        if (date1 instanceof Date && date2 instanceof Date) {
            return date1.getFullYear() >= date2.getFullYear() && date1.getMonth() > date2.getMonth();
        }
        return false;
    }

    compareDates(dateA, dateB) {
        if (dateA > dateB) {
            return 1;
        }

        if (dateB > dateA) {
            return -1;
        }
        return 0;
    }

    isDateValid(date) {
        if (date && date instanceof Date && !isNaN(date)) {
            return true;
        }
        return false;
    }

    /**
     * Converts minutes to milliseconds
     * @param {int} minutes
     * @returns {int} milliseconds
     */
    minutesToMs(minutes) {
        return minutes * (60 * 1000);
    }
}

const helper = new DateHelper();
const utcStringToUTCDate = helper.utcStringToUTCDate;
const toUTCString = helper.toUTCString;
const UTCToLocalDate = helper.UTCToLocalDate;
const minutesToMs = helper.minutesToMs;
const formatTimestampAsZulu = helper.formatTimestampAsZulu;
export default helper;
export { utcStringToUTCDate, UTCToLocalDate, toUTCString, minutesToMs, formatTimestampAsZulu };