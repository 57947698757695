class Polyfill {
    constructor(){
        this.init();
    }
    init(){
        this.watch();
        this.unwatch();
        this.startsWith();
    }

    watch(){
        if (!Object.prototype.watch) {
            Object.defineProperty(Object.prototype, "watch", {
                enumerable: false
            , configurable: true
            , writable: false
            , value: function (prop, handler) {
                var oldval = this[prop], newval = oldval, getter = function () {
                    return newval;
                }
                , setter = function (val) {
                    oldval = newval;
                    return newval = handler.call(this, prop, oldval, val);
                }
                ;
                
                if (delete this[prop]) { // can't watch constants
                    Object.defineProperty(this, prop, {
                            get: getter
                        , set: setter
                        , enumerable: true
                        , configurable: true
                    });
                }
            }
            });
        }
    }

    unwatch(){
        if (!Object.prototype.unwatch) {
            Object.defineProperty(Object.prototype, "unwatch", {
                enumerable: false
            , configurable: true
            , writable: false
            , value: function (prop) {
                var val = this[prop];
                delete this[prop]; // remove accessors
                this[prop] = val;
            }
            });
        }
    }
    endsWith(){
        if (!String.prototype.endsWith) {
            String.prototype.endsWith = function(search, this_len) {
                if (this_len === undefined || this_len > this.length) {
                    this_len = this.length;
                }
                return this.substring(this_len - search.length, this_len) === search;
            };
        }
    }

    startsWith(){
        if (!String.prototype.startsWith) {
            Object.defineProperty(String.prototype, 'startsWith', {
                value: function(search, pos) {
                    return this.substr(!pos || pos < 0 ? 0 : +pos, search.length) === search;
                }
            });
        }
    }

    arrayIncludes(){
        if (!Array.prototype.includes) {
            Object.defineProperty(Array.prototype, 'includes', {
                value: function (searchElement, fromIndex) {
    
                    if (this == null) {
                        throw new TypeError('"this" is null or not defined');
                    }
    
                    const o = Object(this);
                    // tslint:disable-next-line:no-bitwise
                    const len = o.length >>> 0;
    
                    if (len === 0) {
                        return false;
                    }
                    // tslint:disable-next-line:no-bitwise
                    const n = fromIndex | 0;
                    let k = Math.max(n >= 0 ? n : len - Math.abs(n), 0);
    
                    while (k < len) {
                        if (o[k] === searchElement) {
                            return true;
                        }
                        k++;
                    }
                    return false;
                }
            });
        }
    }
    

    

    arrayFrom(){
        if (!Array.from) {
            Array.from = (function () {
              var toStr = Object.prototype.toString;
              var isCallable = function (fn) {
                return typeof fn === 'function' || toStr.call(fn) === '[object Function]';
              };
              var toInteger = function (value) {
                var number = Number(value);
                if (isNaN(number)) { return 0; }
                if (number === 0 || !isFinite(number)) { return number; }
                return (number > 0 ? 1 : -1) * Math.floor(Math.abs(number));
              };
              var maxSafeInteger = Math.pow(2, 53) - 1;
              var toLength = function (value) {
                var len = toInteger(value);
                return Math.min(Math.max(len, 0), maxSafeInteger);
              };
          
              return function from(arrayLike/*, mapFn, thisArg */) {

                var C = this;
                var items = Object(arrayLike);

                if (arrayLike == null) {
                  throw new TypeError('Array.from requires an array-like object - not null or undefined');
                }

                var mapFn = arguments.length > 1 ? arguments[1] : void undefined;
                var T;
                if (typeof mapFn !== 'undefined') {
                  if (!isCallable(mapFn)) {
                    throw new TypeError('Array.from: when provided, the second argument must be a function');
                  }

                  if (arguments.length > 2) {
                    T = arguments[2];
                  }
                }
                var len = toLength(items.length);
                var A = isCallable(C) ? Object(new C(len)) : new Array(len);
                var k = 0;
                var kValue;
                while (k < len) {
                  kValue = items[k];
                  if (mapFn) {
                    A[k] = typeof T === 'undefined' ? mapFn(kValue, k) : mapFn.call(T, kValue, k);
                  } else {
                    A[k] = kValue;
                  }
                  k += 1;
                }

                A.length = len;

                return A;
              };
            }());
          }
    }

    weakMap(){
        (function(){window.WeakSet=b;var c=Date.now()%1E9;function b(a){this.name="__st"+(1E9*Math.random()>>>0)+(c++ +"__");a&&a.forEach&&a.forEach(this.add,this)}var e=b.prototype;e.add=function(a){var d=this.name;a[d]||Object.defineProperty(a,d,{value:!0,writable:!0});return this};e["delete"]=function(a){if(!a[this.name])return!1;a[this.name]=void 0;return!0};e.has=function(a){return!!a[this.name]};})();
        
               
    }
    
}

const singleton = new Polyfill();
export default singleton;