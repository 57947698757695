import axios from 'components/helpers/axios';

class MatchStore {

    constructor() {
        this.matches = null;
    }

    populate() {
        return axios.get(`${process.env.SITE_URL}match_index.json`)
            .then(res => { this.matches = res.data })
            .catch(e => this.matches = [])
    }

    getMatches() {
        if (!(this.matches instanceof Array)) {
            return this.populate().then(() => this.getMatches())
        }

        return Promise.resolve(this.matches)
    }
}

const singleton = new MatchStore();
export default singleton;