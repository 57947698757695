class ImageStore {

  constructor() {
      this.images = []
  }

  populate(list){
      let images = [];
      list.forEach(group => group.images.forEach(image => images.push(image)))
      this.images = images;
  }

  getImage(value){
      let foundImage = this.images.find(image => image.value === value);
      return foundImage ? foundImage.image.file.url : "";
  }
}

const singleton = new ImageStore();
module.exports = singleton;