import 'whatwg-fetch';

const loadGatsbyBuildData = () => {
  return fetch(`${__PATH_PREFIX__}/gatsby-build-data.json`)
    .then((response) => {
      return response.json()
    })
    .catch(e => console.log('Failed fetch gatsby-build-data.json: ' + e));
}

export const onClientEntry = () => {
  if (process.env.NODE_ENV === 'production' && typeof window !== 'undefined') {
    loadGatsbyBuildData().then((gatsbyBuildData) => {
      window.___gatsbyBuildHash = gatsbyBuildData.gatsbyBuildHash;
    })
  }
};

export const onPreRouteUpdate = ({ location, prevLocation }) => {
  if (process.env.NODE_ENV === 'production' && window.___gatsbyBuildHash) {
    loadGatsbyBuildData().then((gatsbyBuildData) => {
      if (gatsbyBuildData.gatsbyBuildHash !== window.___gatsbyBuildHash) {
        window.location = location.pathname;
      }
    })
  }
}