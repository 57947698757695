
class SiteConfigurationStore {
  constructor() {
    this.configuration = []
  }

  populate(configuration){
    this.configuration = configuration;
  }

  getConfiguration(){
      return this.configuration;
  }
}

const helper = new SiteConfigurationStore();
module.exports = helper;
