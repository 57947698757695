import EventAggregator from 'components/helpers/event-aggregator';
import EventAttributeStore from 'components/stores/event-attribute-store';
import EventHub from 'components/events/event-hub';

class GlobalEventAggregatorHelper {
    
    isInitialized;

    constructor() {
      this.isInitialized = false;
    }

    init(){
      if(!this.isInitialized){
        this.isInitialized = true;
        this.pointerDown();
        this.popState();
        this.resize();
        this.scroll();
        this.touchStart();
        this.touchMove();
        this.eventTracking();
      }
    }

    pointerDown(){
      window.addEventListener('pointerdown', (event) => EventAggregator.publish('onPointerDown', event));
    }

    popState(){
      window.addEventListener('popstate', () => (EventAttributeStore.isPopState = true));
    }

    resize(){
      window.addEventListener('resize', e => {
        EventAttributeStore.windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        EventAttributeStore.windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
        EventAggregator.publish('onResizeWindowWidth', EventAttributeStore.windowWidth);
        EventAggregator.publish('onResizeWindowHeight', EventAttributeStore.windowHeight);
      });
    }
    
    scroll(){
      window.addEventListener('scroll', () => {
        EventAttributeStore.scrollY = window.scrollY || window.pageYOffset || document.documentElement.scrollTop;
        EventAggregator.publish('onScroll', EventAttributeStore.scrollY);
      });
    }

    touchStart(){
        window.addEventListener('touchstart', (event) =>  EventAggregator.publish('onTouchStart', event));
    }

    touchMove(){
      window.addEventListener('touchmove', () => {
        let scrollTop = window.scrollY || window.pageYOffset || document.documentElement.scrollTop;
  
        if (scrollTop !== EventAttributeStore.scrollY) {
          EventAttributeStore.scrollY = scrollTop;
          EventAggregator.publish('onScroll', EventAttributeStore.scrollY);
        }
      });
    }

    eventTracking() {
      EventAggregator.subscribe("trackEvent", (data) =>  EventHub.trackEvent(data));
      EventAggregator.subscribe("trackPageview", (data) => EventHub.trackPageview(data));
    }
}

const singleton = new GlobalEventAggregatorHelper();
export default singleton;