// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admin-panel-betting-companies-index-js": () => import("./../../../src/pages/admin-panel/betting/companies/index.js" /* webpackChunkName: "component---src-pages-admin-panel-betting-companies-index-js" */),
  "component---src-pages-admin-panel-betting-create-index-js": () => import("./../../../src/pages/admin-panel/betting/create/index.js" /* webpackChunkName: "component---src-pages-admin-panel-betting-create-index-js" */),
  "component---src-pages-admin-panel-betting-images-index-js": () => import("./../../../src/pages/admin-panel/betting/images/index.js" /* webpackChunkName: "component---src-pages-admin-panel-betting-images-index-js" */),
  "component---src-pages-admin-panel-betting-index-js": () => import("./../../../src/pages/admin-panel/betting/index.js" /* webpackChunkName: "component---src-pages-admin-panel-betting-index-js" */),
  "component---src-pages-admin-panel-groups-index-js": () => import("./../../../src/pages/admin-panel/groups/index.js" /* webpackChunkName: "component---src-pages-admin-panel-groups-index-js" */),
  "component---src-pages-admin-panel-images-index-js": () => import("./../../../src/pages/admin-panel/images/index.js" /* webpackChunkName: "component---src-pages-admin-panel-images-index-js" */),
  "component---src-pages-admin-panel-index-js": () => import("./../../../src/pages/admin-panel/index.js" /* webpackChunkName: "component---src-pages-admin-panel-index-js" */),
  "component---src-pages-admin-panel-leagues-index-js": () => import("./../../../src/pages/admin-panel/leagues/index.js" /* webpackChunkName: "component---src-pages-admin-panel-leagues-index-js" */),
  "component---src-pages-admin-panel-sorting-index-js": () => import("./../../../src/pages/admin-panel/sorting/index.js" /* webpackChunkName: "component---src-pages-admin-panel-sorting-index-js" */),
  "component---src-pages-admin-panel-stadiums-index-js": () => import("./../../../src/pages/admin-panel/stadiums/index.js" /* webpackChunkName: "component---src-pages-admin-panel-stadiums-index-js" */),
  "component---src-pages-admin-panel-teams-index-js": () => import("./../../../src/pages/admin-panel/teams/index.js" /* webpackChunkName: "component---src-pages-admin-panel-teams-index-js" */),
  "component---src-pages-admin-panel-tournaments-index-js": () => import("./../../../src/pages/admin-panel/tournaments/index.js" /* webpackChunkName: "component---src-pages-admin-panel-tournaments-index-js" */),
  "component---src-templates-content-page-js": () => import("./../../../src/templates/content-page.js" /* webpackChunkName: "component---src-templates-content-page-js" */),
  "component---src-templates-match-page-js": () => import("./../../../src/templates/match-page.js" /* webpackChunkName: "component---src-templates-match-page-js" */)
}

