class SessionStorageHelper {

    isSessionStorageAvailable() {
        try { sessionStorage.isAvailable = true; }
        catch (e) { return false; }
        return true;
    }

    setItem(key, value, timestamp) {
        if (!key || !value) { return; }

        if (timestamp) {
            value = { value: value, sessionStorageItemValidUntil: timestamp };
        }

        if (typeof value === "object") {
            value = JSON.stringify(value);
        }

        sessionStorage.setItem(key, value);
    }

    isTimeStampValid(timestamp) {
        let now = new Date();
        return timestamp >= (now.setMinutes(now.getMinutes()));
    }

    getItem(key) {
        let value = sessionStorage.getItem(key);
        if (!value) { return; }
        if (value[0] === "{" || value[0] === "[") {
            value = JSON.parse(value);
        }

        if (value && !value.sessionStorageItemValidUntil) {
            return value;
        }

        return this.valueTimeStampValidation(value, key);
    }

    valueTimeStampValidation(value, key) {
        if (this.isTimeStampValid(value.sessionStorageItemValidUntil)) {
            return value.value;
        } else {
            this.removeItem(key);
            return undefined;
        }
    }

    removeItem(key) {
        sessionStorage.removeItem(key);
    }
}

const helper = new SessionStorageHelper();
export default helper;