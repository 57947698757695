class TeamStore {

    constructor() {
        this.teams = [];
    }

    populate(list) {
        let teams = list.filter(li => li.team && li.team.team && li.team.team.id)
        this.teams = teams.map(li => {
            return {
                id: li.team && li.team.team && li.team.team.id,
                name: li.team.team.name ? li.team.team.name : "",
                abbr: li.team.team.abbr ? li.team.team.abbr : "",
                logo: li.logo ? li.logo.file.url : "",
            }
        });
    }

    getTeam(id) {
        let foundTeam = this.teams.find(team => team.id === id);
        return foundTeam ? foundTeam : "";
    }

    getTeamLogo(id) {
        let foundTeam = this.teams.find(team => team.id === id);
        return foundTeam && foundTeam.logo ? foundTeam.logo : "";
    }

    async getTeamLogoEnetpulse(id) {
        return await fetch(`https://eapi.enetpulse.com/image/team_logo/?teamFK=${id}&username=matchcenterapiusr&token=42fc5f26354966eaa1b2255e6647c795`)
            .then(async response => {
                // debugger
                return await response.json().then((r) => {
                    return `data:image/png;base64,  ${Object.values(r.images).filter(i => i.contenttype === 'image/png')[0].value}`
                });

            })
            .catch(e => {
            })
    }

    getCleanSportRadarId(competitorId) {
        return competitorId && competitorId.lastIndexOf(':') > -1 ? competitorId.substring(competitorId.lastIndexOf(':') + 1, competitorId.length) : competitorId;
    }
}

const singleton = new TeamStore();
module.exports = singleton;