import EventAggregator from 'components/helpers/event-aggregator';

class InteractionHelper {
    
    userInput;

    constructor() {
        EventAggregator.subscribe("onPointerDown",this.pointerDownEvent.bind(this));
        EventAggregator.subscribe("onTouchStart",this.setInteractionAsTouch.bind(this));        
    }

    pointerDownEvent(e) {   
        if (e && e.pointerType === "mouse") {
            this.setInteractionAsMouse();
        }
        else if (e && e.pointerType === "touch") {
            this.setInteractionAsTouch();
        }
    }

    setInteractionAsMouse(force) {
        if(this.userInput === "touch" || force){
            this.userInput = "mouse";
            document.body.classList.remove("touch");
            document.body.classList.add("mouse");
        }
    }

    setInteractionAsTouch(force) {
        if(this.userInput === "mouse" || force){
            this.userInput = "touch";
            document.body.classList.remove("mouse");
            document.body.classList.add("touch");
        }
    }

    setDefaultState(){
        this.setInteractionAsMouse(true);
    }
}

const singleton = new InteractionHelper();
export default singleton;