class BookingAgencyStore {

    constructor() {
        this.agencies = [];
    }

    populate(list){
        let agencies = list.filter(li => li.bookingAgency && li.bookingAgency.agency && li.bookingAgency.agency.id)
        this.agencies = agencies.map(li => {
            return {
                id : li.bookingAgency.agency.id,
                name : li.bookingAgency.agency.name,
                alias : li.alias ? li.alias : "",
                logo : li.logo ? li.logo.file.url : "",
                url : li.bookingAgency.agency.url ? li.bookingAgency.agency.url : ""
            }
        });
    }
  
    getAgency(id){
        let foundAgency = this.agencies.find(agency => agency.id === id);
        return foundAgency ? foundAgency : "";
    }

    getAgencyLogo(id){
        let foundAgency = this.agencies.find(agency => agency.id === id);
        return foundAgency && foundAgency.logo ? foundAgency.logo : "";
    }
}

const singleton = new BookingAgencyStore();
module.exports = singleton;