class TagManager {

    dataLayerPush(data) {
        if(typeof window !== 'undefined' && window.dataLayer) {
            window.dataLayer.push(data);
        }

    }
    /**
     * GA event object structure: https://developers.google.com/analytics/devguides/collection/analyticsjs/events
     */
    registerEvent(event) {
        if(event && event.category && event.action) {
            this.dataLayerPush({
                'event': 'GAEvent',
                'eventCategory': event.category,
                'eventAction': event.action,
                'eventLabel': event.label ? event.label : undefined,
                'eventValue': event.value ? event.value : undefined
            });
        }
    }

    /**
     * GA event object structure: https://developers.google.com/analytics/devguides/collection/analyticsjs/events
     */
    registerPageview(event) {
        if(event && event.virtualPagePath) {
            this.dataLayerPush({
                'event': 'VirtualPageview',
                'virtualPagePath': event.virtualPagePath,
                'virtualPageTitle': event.virtualPageTitle ? event.virtualPageTitle : ''
            });
        }
    }
}

const events = new TagManager();
export default events;
