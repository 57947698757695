import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import SiteConfigurationStore from 'components/stores/site-configuration-store'
import TranslationStore from 'components/stores/translation-store';
import ImageStore from 'components/stores/image-store'
import BokingAgencyStore from 'components/stores/booking-agency-store'
import TeamStore from 'components/stores/team-store'

const StoreFactory = (props) => {
    const data = useStaticQuery(query);
    // debugger
    TranslationStore.populate(data.contentfulSiteTranslations.translations);
    ImageStore.populate(data.contentfulSiteImages.images)
    SiteConfigurationStore.populate(data.contentfulSiteConfiguration);
    BokingAgencyStore.populate(data.allContentfulBookingAgency.edges.map(edge => edge.node))
    TeamStore.populate(data.allContentfulTeam.edges.map(edge => edge.node))
    return <>{props.children}</>
}
const query = graphql`{
    contentfulSiteConfiguration{
        ...SiteConfiguration
    }
    contentfulSiteTranslations(isActive : { eq : true }){
        ...SiteTranslations
    }
    contentfulSiteImages(isActive : { eq : true }){
        ...SiteImages
    }
    allContentfulBookingAgency {
        edges {
            node {
                ...BookingAgency
            }
        }
    }
    allContentfulTeam {
        edges {
          node {
            ...Team
          }
        }
    }
}`

export default StoreFactory;